@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");

/* scrobar */
::-webkit-scrollbar {
  width: 0px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.chat-box {
  width: 100%;
  max-width: 390px;
}
.chatBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.chatBtn > button {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  float: right;
}

.queryInput fieldset {
  border-radius: 30px;
}
.queryInput .Mui-focused fieldset {
  border-color: #e52900 !important;
  border-width: 1px !important;
}

.inputSection {
  position: sticky;
  margin-top: auto;
  left: 0px;
  bottom: 0px;
  /* right: 0px; */
  width: auto !important;
  background-color: #fff;
  /* border-top: 1px solid; */
  box-shadow: none;
  padding: 10px;
}
.animated-image {
  display: flex;
  justify-content: center;
}
.conversations {
  padding: 10px;
  background-color: #f6f7f9;
  border-radius: 10px;
  display: flex;
}
.chatbox-row {
  margin: 0px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: hidden; */
}
.aiConv {
  max-width: 80%;
  border-radius: 15px 15px 15px 0px;
  background-color: rgba(229, 0, 0, 0.05);
  padding: 15px 15px;
  color: #111111;
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}
.aiConv.loader {
  min-width: 36px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userConv {
  max-width: 70%;
  background-color: #f20000;
  border-radius: 15px 15px 0px 15px;
  /* border: 1px solid #ccc; */
  margin-left: auto;
  padding: 10px 15px;
  color: #111111;
  font-size: 15px;
  font-weight: 400;
  /* line-height: 160%; */
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #111;
  color: #111;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #111;
  color: #111;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.dealButton {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
}

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes animateFlip {
  100% {
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 500ms;
  animation-fill-mode: both;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  animation-duration: 700ms;
  animation-fill-mode: both;
}
.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  animation-duration: 700ms;
  animation-fill-mode: both;
}
.animate__flip {
  -webkit-animation-name: animateFlip;
  animation-name: animateFlip;
  animation-duration: 700ms;
  animation-fill-mode: both;
}
.hid {
  overflow: hidden;
  min-height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputSection input {
  padding-left: 20px;
  padding-right: 20px;
}
.send-button svg {
  width: 25px;
  height: 25px;
}

.drawerBox .title-text span,
.title-text {
  text-align: left;
  text-transform: capitalize;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #111111;
}

.event_image {
  /* width: 200px; */
  height: 151px;
}
button.close-button,
.close-button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  min-width: auto;
  padding: 0px;
  margin-right: 10px;
}

.chatbox-loader {
  width: 36px;
  height: 14px;
  background: radial-gradient(circle closest-side, #555555 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #555555 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #555555 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 6px;
  background-repeat: no-repeat;
  animation: loader 1s infinite linear;
}
@keyframes loader {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.MuiPaper-elevation {
  border-radius: 8px;
  bottom: 1px;
  box-shadow: 0 2px 5px -1px #32325d40, 0 1px 3px -1px #0000004d !important;
  max-height: 750px;
  max-width: 400px;
  right: 2px !important;
  top: auto !important;
  width: 98%;
}

.company-ref {
  font-size: 14px;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #4f4444;
}
.company-ref a {
  margin-left: 5px;
  color: #e50000;
  text-decoration: none;
}

.MuiModal-backdrop {
  background-color: transparent !important;
}

@media (max-width: 415px) {
  .MuiPaper-elevation {
    bottom: 0px;
    max-height: none;
    max-width: none;
    right: 0px !important;
    width: 100%;
    border-radius: 0px;
  }
}

.accepted_offer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accepted_offer p {
  color: #e52900;
  font-size: 20px;
  font-weight: 700;
}
.discount-hr-image {
  position: relative;
  z-index: 1;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.discount-hr-image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: #ddd;
  z-index: -1;
}

.discount-hr-image > img {
  background-color: #fff;
  height: 70px;
  object-fit: contain;
  padding: 0 20px;
  width: 70px;
}

.discount-span-text {
  background-color: #e52900;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  margin: 0 5px;
  padding: 8px 12px;
}
